<template>
  <bm-overlay ref="customOverlay" :class="{sample: true, active}" pane="labelPane" @draw="draw">
     <div>
        <p style="font-weight: 600;font-size: 12px;">{{text}}</p>
        <p style="font-weight: 600;font-size: 12px;">梯:{{elevatorNum}} 困:{{peopleTrappedCount}} 挡:{{electroMobileCount}}</p>
      </div>
  </bm-overlay>
  <!--  -->
</template>

<script>
export default {
  props: [
    'text',
    'position',
    'active',
    'elevatorNum',
    'peopleTrappedCount',
    'electroMobileCount',
  ],
  watch: {
    position: {
      handler() {
        this.$refs.customOverlay.reload()
      },
      deep: true
    }
  },
  methods: {
    draw({ el, BMap, map }) {
      const { lng, lat } = this.position
      const pixel = map.pointToOverlayPixel(new BMap.Point(lng, lat))
      el.style.left = pixel.x - 60 + 'px'
      el.style.top = pixel.y - 20 + 'px'
    }
  }
}
</script>

<style>
.sample {
  width: 80px;
  height: 80px;
  padding:0 8px;
  text-align: center;
  border-radius: 40px;
  /* background: #2d8cf0; */
  background: #2d8cf0;
  box-shadow: 0 0 10px rgb(56, 56, 56);
  color: white;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}
.sample::-webkit-scrollbar {
  display: none;
}
.sample.active {
  background: rgba(0, 0, 0, 0.75);
  color: #fff;
}
</style>